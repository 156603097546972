import ThreatDetailModal from "../modal/ThreatDetailModal";

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

const index = 'demo2';
const demoThreat = {
  access_clock: 'アクセスがあった日時です。',
  client_ip: 'アクセス元の IP アドレスです。',
  attack_method: 'アクセスの種類です。',
  request: 'アクセスの内容です。'
};

const ThreatDetailModalDemo = () => {
  return (
    <Card>
      <CardContent>
        <ThreatDetailModal index={index} threat={demoThreat} isDemo={true} needModalContentOnly={true} />
      </CardContent>
    </Card>
  );
};

export default ThreatDetailModalDemo;
