import { getIPNames } from "../../threat/util/fetch";
import IPNameDealer from "./IPNameDealer";

import { useEffect, useState } from 'react';

const IPNameCasino = ({ params={} }) => {
  const defaultDisplayN = 10;

  const [ipnames, setIPNames] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    (async () => {
      setIPNames(await getIPNames({ limit: defaultDisplayN }));
      setIsLoading(false);
    })();
  }, []);

  return (
    <IPNameDealer
      ipnames={ipnames}
      updateDeck={async (params) => {
        setIsLoading(true);
        setIPNames(await getIPNames(params=params));
        setIsLoading(false);
      }}
      isWaitingDeck={isLoading}
    />
  );
};

export default IPNameCasino;
