import { getMaliciousAccessScale } from "../../threat/util/fetch";

import { useEffect, useState } from "react";
import { LineChart } from '@mui/x-charts/LineChart';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';

const yLabel1 = 'グラフ3'

const Chart6 = () => {
  const today = new Date().toLocaleString('sv', {timeZone: 'Asia/Tokyo'}).replaceAll('-', '/').substring(0, 10);
  const dateFormat = /^\d{4}\/\d{2}\/\d{2}$/;
  const [xAxisData, setXAxisData] = useState([]);
  const [yAxisData1, setYAxisData1] = useState([]);
  const [start, setStart] = useState(`${today.split('/')[0]}/01/01`);
  const [end, setEnd] = useState(today);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const update = async () => {
      const [y1, m1, d1] = start.split('/');
      const [y2, m2, d2] = end.split('/');
      const j = await getMaliciousAccessScale({'y1': y1, 'm1': m1, 'd1': d1, 'y2': y2, 'm2': m2, 'd2': d2});
      const sortedJ = Object.entries(j).sort((one, another) => one[0] < another[0] ? -1 : 1);

      setXAxisData(sortedJ.map(x => x[0]));
      setYAxisData1(sortedJ.map(x => x[1]));
      setIsLoading(false);
    };

    if (!dateFormat.test(start) || !dateFormat.test(end)) {
      return;
    }
    if (start >= end) {
      return;
    }

    setIsLoading(true);
    update();
  }, [start, end]);

  return (
    <>
      <Box
        sx={{ width: '100%' }}
        mt={5}
        mb={2}
      >
        <Box sx={{ display: 'flex' }} gap={2}>
          <Box>
            <Typography fontSize='h6.fontSize' fontWeight='fontWeightBold'>
                期間
              </Typography>
              <Typography fontSize={14}>
                入力例: 2023年11月7日 → 2023/11/07
            </Typography>
          </Box>
          <Box>
            <TextField
                label='Start'
                variant='filled'
                value={start}
                onChange={(e) => {
                  setStart(e.target.value);
                }}
            />
          </Box>
          <Box>
            <TextField
              label='End'
              variant='filled'
              value={end}
              onChange={(e) => {
                setEnd(e.target.value);
              }}
            />
          </Box>
        </Box>
      </Box>
      <Box sx={{ width: '100%' }}>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <LineChart
            xAxis={[{ data: xAxisData, scaleType: 'band' }]}
            yAxis={[{ scaleType: 'linear', min: 0 }]}
            series={[
              // { data: yAxisData1, label: yLabel1 },
              { data: yAxisData1 }
            ]}
            height={400}
          />
        )}
      </Box>
    </>
  );
};

export default Chart6;
