import Chart4 from '../comps/Chart4';
import Chart5 from '../comps/Chart5';
import Chart6 from '../comps/Chart6';
import Chart7 from '../comps/Chart7';
import IPNameCasino from '../comps/IPNameCasino';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const TestCore = () => {
  return (
    <>
      <Box
        sx={{ width: '100%' }}
        mt={2}
        mb={2}
      >
        <Typography component='div'>
          <Box mt={5} mb={3} fontSize='h3.fontSize' fontWeight='fontWeightBold'>
            研究室へのアクセスの見える化
          </Box>
          <Box fontSize='h6.fontSize' mt={1} mb={1} ml={2}>
            私たちは2023年7月5日から、研究室のWebサーバのログをiLogScannerを用いてモニタリングしています。iLogScannerはログから「SQLインジェクション」などのサイバー攻撃を検出するIPAのツールです。
          </Box>
          <Box fontSize='h6.fontSize' mt={1} mb={1} ml={2}>
            そのなかで、iLogScannerが検出したアクセス（以降、不審なアクセス）も含めて、こちらで共有します。
          </Box>
        </Typography>
      </Box>
      <Box
        sx={{ width: '100%' }}
        mt={2}
        mb={2}
      >
        <Typography component='div'>
          <Box mt={5} mb={3} fontSize='h4.fontSize' fontWeight='fontWeightBold'>
            不審なアクセス元の名前一覧
          </Box>
          <Box fontSize='h6.fontSize' mt={1} mb={1} ml={2}>
            不審なアクセスをしてきた機器のドメイン名の一覧です。該当する組織は、不審なアクセスをしている、または第三者にサイバー攻撃に悪用されている（= 踏み台にされている）可能性があります。
          </Box>
          <Box fontSize='h6.fontSize' mt={1} mb={1} ml={2}>
            該当した組織のなかで心当たりのない場合は、踏み台にされている可能性があるので、確認してみてください。
          </Box>
        </Typography>
        <Box
          sx={{ width: '100%' }}
          mt={2}
          mb={2}
        >
          <IPNameCasino />
        </Box>
      </Box>
      <Box
        sx={{ width: '100%' }}
        mt={2}
        mb={2}
      >
        <Typography component='div'>
          <Box mt={5} mb={3} fontSize='h4.fontSize' fontWeight='fontWeightBold'>
            研究室への総アクセスの推移
          </Box>
          <Box fontSize='h6.fontSize' mt={1} mb={1} ml={2}>
            研究室へのすべてのアクセスの推移です。「通常のアクセス」、「不審なアクセス」の総数を表示しています。
          </Box>
        </Typography>
        <Box
          sx={{ width: '100%' }}
          mt={2}
          mb={2}
        >
          <Chart4 />
        </Box>
      </Box>
      <Box
        sx={{ width: '100%' }}
        mt={2}
        mb={2}
      >
        <Typography component='div'>
          <Box mt={5} mb={3} fontSize='h4.fontSize' fontWeight='fontWeightBold'>
            研究室への総アクセスの年比較
          </Box>
          <Box fontSize='h6.fontSize' mt={1} mb={1} ml={2}>
            研究室への「通常のアクセス」「不審なアクセス」の総数を年ごとに表示しています。
          </Box>
        </Typography>
        <Box
          sx={{ width: '100%' }}
          mt={2}
          mb={2}
        >
          <Chart5 />
        </Box>
      </Box>
      <Box
        sx={{ width: '100%' }}
        mt={2}
        mb={2}
      >
        <Typography component='div'>
          <Box mt={5} mb={3} fontSize='h4.fontSize' fontWeight='fontWeightBold'>
            不審なアクセスの推移
          </Box>
          <Box fontSize='h6.fontSize' mt={1} mb={1} ml={2}>
            研究室への「不審なアクセス」の推移です。
          </Box>
        </Typography>
        <Box
          sx={{ width: '100%' }}
          mt={2}
          mb={2}
        >
          <Chart6 />
        </Box>
      </Box>
      <Box
        sx={{ width: '100%' }}
        mt={2}
        mb={2}
      >
        <Typography component='div'>
          <Box mt={5} mb={3} fontSize='h4.fontSize' fontWeight='fontWeightBold'>
            不審なアクセスの年比較
          </Box>
          <Box fontSize='h6.fontSize' mt={1} mb={1} ml={2}>
            研究室への「不審なアクセス」を年ごとに比較できます。
          </Box>
        </Typography>
        <Box
          sx={{ width: '100%' }}
          mt={2}
          mb={2}
        >
          <Chart7 />
        </Box>
      </Box>
    </>
  );
}

export default TestCore;
