import { useState } from 'react';
import { Link } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import AttributionIcon from '@mui/icons-material/Attribution';
import InfoIcon from '@mui/icons-material/Info';
import WebAssetIcon from '@mui/icons-material/WebAsset';
import ExploreIcon from '@mui/icons-material/Explore';
import ContactsIcon from '@mui/icons-material/Contacts';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import SchoolIcon from '@mui/icons-material/School';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import { useTheme } from '@emotion/react';
import { ReactComponent as Logo } from './icon.svg';

const InboundLink = [
  {
    title: 'ホーム',
    Icon: HomeIcon,
    link: '/'
  },
  {
    title: '実証実験',
    Icon: AttributionIcon,
    link: undefined,
    child: [
      // {
      //   title: '使い方',
      //   Icon: InfoIcon,
      //   link: '/threat/guide'
      // },
      {
        title: '見える化',
        Icon: WebAssetIcon,
        link: '/threat/asset'
      },
      // {
      //   title: 'Concept',
      //   Icon: ExploreIcon,
      //   link: '/threat/concept'
      // }
    ]
  }
];
const OutboundLink = [
  {
    title: '研究室活動',
    Icon: NoteAltIcon,
    link: 'https://blog.isl.im.dendai.ac.jp/'
  },
  {
    title: 'TDU',
    Icon: SchoolIcon,
    link: 'https://dendai.ac.jp'
  }
];

const Arena2 = ({ Contents }) => {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const [anchorEl1, setAnchorEl1] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const handlePopoverOpen1 = (event) => {
    setAnchorEl1(event.currentTarget);
  }
  const handlePopoverClose1 = (event) => {
    setAnchorEl1(null);
  }

  const handlePopoverOpen2 = (event) => {
    setAnchorEl2(event.currentTarget);
  }
  const handlePopoverClose2 = (event) => {
    setAnchorEl2(null);
  }

  const [key, setKey] = useState('');
  const handleClick = (title) => {
    if (key === title) {
      setKey(undefined);
    } else {
      setKey(title);
    }
  };

  const theme = useTheme();

  const menuListConstructor = ({ menuListContents, outbound=false }) => {
    const construct = (contents, level) => {
      return contents.map(({ title, Icon, link, child }) => {
        if (child === undefined) {
          return (
            <ListItem sx={{ pl: 2*level }} key={title} disablePadding>
              <ListItemButton component={Link} to={link} target={outbound ? '_blank' : '_self'}>
                <ListItemIcon>
                  <Icon />
                </ListItemIcon>
                <ListItemText primary={title} />
              </ListItemButton>
            </ListItem>
          );
        } else {
          return (
            <>
              <ListItem sx={{ pl: 2*level }} key={title} disablePadding>
                <ListItemButton onClick={() => handleClick(title)} target={outbound ? '_blank' : '_self'}>
                  <ListItemIcon>
                    <Icon />
                  </ListItemIcon>
                  <ListItemText primary={title} />
                  {key === title ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
              </ListItem>
              <Collapse in={key === title} timeout='auto' unmountOnExit>
                <List component='div'>
                  {construct(child, level+1)}
                </List>
              </Collapse>
            </>
          );
        }
      });
    };

    return (
      <List>
        {construct(menuListContents, 0)}
      </List>
    )
  };

  return (
    <>
      <AppBar position='sticky' style={{ "background-color": "#fafafa" }}>
        <Container maxWidth='xl' sx={{ height: '65px' }}>
          <Toolbar disableGutters>
            <Button component={Link} to={'/'}>
              <Logo />
            </Button>
            <Box sx={{ flexGrow: 1, justifyContent: "flex-end", display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="primary"
              >
                <MenuIcon style={{ color: theme.palette.primary.main }} />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                { menuListConstructor({ menuListContents: InboundLink }) }
                <Divider />
                { menuListConstructor({ menuListContents: OutboundLink }) }
              </Menu>
            </Box>
            <Box sx={{ flexGrow: 1, pl: 5, gap: 2, display: { xs: 'none', md: 'flex' } }}>
              <>
                <Typography
                  aria-owns={ Boolean(anchorEl1) ? 'mouse-over-popover' : undefined }
                  aria-haspopup="true"
                  onMouseEnter={handlePopoverOpen1}
                  color="primary"
                  style={{ "font-weight": "bold" }}
                >
                  実証実験
                </Typography>
                <Popover
                  id="mouse-over-popover"
                  sx={{ width: "100%", minHeight: "150px" }}
                  open={Boolean(anchorEl1)}
                  anchorEl={anchorEl1}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  onClose={handlePopoverClose1}
                  
                  disableRestoreFocus
                >
                  <Box
                    sx={{
                      width: "calc(100vw - 32px)",
                      minHeight: "150px",
                      p: 1,
                    }}
                    onMouseLeave={handlePopoverClose1}
                  >
                    <Box sx={{pl: 5, minHeight: "150px", display: "flex", alignItems: "center", gap: 1 }}>
                      {InboundLink[1]['child'].map(({ title, Icon, link }) => (
                        <Button
                        style={{ "font-weight": "bold", "font-size": "20px" }}
                        variant='outlined'
                        startIcon={<Icon />}
                        size='large'
                        component={Link}
                        to={link}
                      >
                        {title}
                      </Button>
                      ))}
                    </Box>
                  </Box>
                </Popover>
              </>
              <>
                <Typography
                  aria-owns={ Boolean(anchorEl1) ? 'mouse-over-popover' : undefined }
                  aria-haspopup="true"
                  onMouseEnter={handlePopoverOpen2}
                  color="primary"
                  style={{ "font-weight": "bold" }}
                >
                  関連リンク
                </Typography>
                <Popover
                  id="mouse-over-popover"
                  sx={{ width: "100%", minHeight: "150px" }}
                  open={Boolean(anchorEl2)}
                  anchorEl={anchorEl2}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  onClose={handlePopoverClose2}
                  
                  disableRestoreFocus
                >
                  <Box
                    sx={{
                      width: "calc(100vw - 32px)",
                      minHeight: "150px",
                      p: 1,
                    }}
                    onMouseLeave={handlePopoverClose2}
                  >
                    <Box sx={{ pl: 5, minHeight: "150px", display: "flex", alignItems: "center", gap: 1 }}>
                      <Button
                        style={{ "font-weight": "bold", "font-size": "20px" }}
                        variant='outlined'
                        startIcon={<NoteAltIcon />}
                        size='large'
                        component={Link}
                        to={OutboundLink[0]['link']}
                      >
                        研究室活動
                      </Button>
                      <Button
                        style={{ "font-weight": "bold", "font-size": "20px" }}
                        variant='outlined'
                        startIcon={<SchoolIcon />}
                        size='large'
                        component={Link}
                        to={OutboundLink[1]['link']}
                      >
                        TDU
                      </Button>
                    </Box>
                  </Box>
                </Popover>
              </>

            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Contents />
    </>
  );
}
export default Arena2;
