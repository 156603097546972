import IPNameCard from './IPNameCard';

import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import useMediaQuery from '@mui/material/useMediaQuery';

const IPNameDeck = ({ ipnames }) => {
  const displayLimit = '6';
  const [displayBeyondLimit, setDisplayBeyondLimit] = useState(false);
  const [displayIPNames, setDisplayIPNames] = useState(ipnames);
  useEffect(() => {
    const newDisplayIPNames = [];
    for (var i in ipnames) {
      if (!displayBeyondLimit && i >= displayLimit) {
        break;
      }
      newDisplayIPNames.push(ipnames[i])
    }
    setDisplayIPNames(newDisplayIPNames);
  }, [displayBeyondLimit]);

  const isWideScreen = useMediaQuery('(min-width:1000px)');
  const cardsPerRow = isWideScreen ? 3 : 1;
  return (
    <Box
      mt={1}
      mb={1}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: `repeat(${cardsPerRow}, minmax(250px, 1fr))`,
          gap: 2,
          width: '100%',
        }}
        mt={1}
        mb={1}
      >
        {displayIPNames.map((ipname, index) => (
          <IPNameCard index={index} ipname={ipname} />
        ))}
      </Box>
      <Box
        mt={1}
        mb={1}
        textAlign='center'
      >
        <IconButton size='large' onClick={() => setDisplayBeyondLimit(!displayBeyondLimit)}>
          {!displayBeyondLimit ? (
            <AddCircleOutlineIcon />
          ) : (
            <RemoveCircleOutlineIcon />
          )}
        </IconButton>
      </Box>
    </Box>
  );
};

export default IPNameDeck;
