import { SHA256 } from 'crypto-js';

import { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from "@mui/material/InputAdornment";
import Grid from '@mui/material/Unstable_Grid2';

const Landing = () => {
  const [left, setLeft] = useState('');
  const [readyLeft, setReadyLeft] = useState(false);
  const [right, setRight] = useState('');
  const [readyRight, setReadyRight] = useState(false);

  const verify = async(left, right) => {
    const q = SHA256(left + right).toString();
    // const URL = '/api/verify/new'
    const URL = 'https://172.18.0.46/api/verify/new'
    await fetch(URL, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({q: q})
    })
    .then(async (res) => {
      if (res.redirected) {
        window.location.href = res.url;
      }
    })
    .catch((err) => {
      console.error(err);
    })
  };

  useEffect(() => {
    if (readyLeft && readyRight) {
      verify(left, right);
    }
  }, [left, right]);

  return (
    <Box>
      <Box component='form' sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
        <Grid container spacing={2}>
          <Grid xs={6}>
            <TextField
              label='left'
              variant='filled'
              value={left}
              onChange={(e) => {
                setLeft(e.target.value);
                setReadyLeft(e.target.value.length === 3);
              }}
            />
          </Grid>
          <Grid xs={6}>
            <TextField
              label='right'
              variant='filled'
              value={right}
              onChange={(e) => {
                setRight(e.target.value);
                setReadyRight(e.target.value.length === 8);
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Landing;
