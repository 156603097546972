import ThreatCard from "../casino/ThreatCard";

const index = 'demo1';
const demoThreat = {
  access_clock: 'アクセスがあった日時です。',
  client_ip: 'アクセス元の IP アドレスです。',
  attack_method: 'アクセスの種類です。',
  request: 'アクセスの内容です。'
};

const ThreatCardDemo = () => {
  return (
    <ThreatCard index={index} threat={demoThreat} isDemo={true} />
  );
};

export default ThreatCardDemo;
