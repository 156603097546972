import Origin from '../origin/Origin';
import GuideCore from './core/GuideCore';

const Guide = () => {
  return (
    <Origin Core={GuideCore} />
  );
};

export default Guide;
