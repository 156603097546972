import { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AttributionIcon from '@mui/icons-material/Attribution';
import HeartBrokenIcon from '@mui/icons-material/HeartBroken';


const IPNameCard = ({ index, ipname, isDemo=false }) => {
  const [open, setOpen] = useState(false);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);

  return (
    <Box>
      <Card>
        <CardActionArea>
          <CardContent>
            <Typography component='div'>
              <Box fontSize='h7.fontSize' fontWeight='fontWeightBold'>
                {ipname.ip_addr}
              </Box>
            </Typography>
            <Divider />
            <Typography component='div'>
              <Box fontSize='h5.fontSize'>
                {ipname.domain}
              </Box>
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Box>
  );
};

export default IPNameCard;
