import SpringModal from "../../../util/modal/SpringModal";
import SQLInjectionTipsModal from "./SQLInjectionTipsModal";
import OSCommandInjectionTipsModalTipsModal from "./OSCommandInjectionTipsModal";
import DirectoryTraversalTipsModal from "./DirectoryTraversalTipsModal";
import XSSTipsModal from "./XSSTipsModal";

const SQL_INJECTION = 'SQLインジェクション';
const OS_COMMAND_INJECTION = 'OSコマンドインジェクション';
const DIRECTORY_TRAVERSAL = 'ディレクトリトラバーサル';
const XSS = 'クロスサイトスクリプティング';

const AttackMethodTipsModal = ({ open, handleClose, attackMethod }) => {
  if (attackMethod === SQL_INJECTION) {
    return (
      <SpringModal open={open} handleClose={handleClose} ModalContent={SQLInjectionTipsModal} />
    );
  } else if (attackMethod === OS_COMMAND_INJECTION) {
    return (
      <SpringModal open={open} handleClose={handleClose} ModalContent={OSCommandInjectionTipsModalTipsModal} />
    );
  } else if (attackMethod === DIRECTORY_TRAVERSAL) {
    return (
      <SpringModal open={open} handleClose={handleClose} ModalContent={DirectoryTraversalTipsModal} />
    );
  } else if (attackMethod === XSS) {
    return (
      <SpringModal open={open} handleClose={handleClose} ModalContent={XSSTipsModal} />
    );
  }

  return (
    <></>
  );
};

export default AttackMethodTipsModal;
