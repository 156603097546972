import { getAccessScale } from "../../threat/util/fetch";

import { useEffect, useState } from "react";
import { LineChart } from '@mui/x-charts/LineChart';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';

const graphNames = ['グラフ1', 'グラフ2']

const Chart5 = () => {
  const yearFormat = /^\d{4}$/;
  const [graphXData, setGraphXData] = useState([]);
  const [graph1YData, setGraph1YData] = useState([]);
  const [graph2YData, setGraph2YData] = useState([]);
  const [graph1Year, setGraph1Year] = useState('2022');
  const [graph2Year, setGraph2Year] = useState('2023');
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const update = async (y, graphDataSetter) => {
      const [y1, m1, d1] = [y, '01', '01'];
      const [y2, m2, d2] = [y, '12', '31'];
      const j = await getAccessScale({'y1': y1, 'm1': m1, 'd1': d1, 'y2': y2, 'm2': m2, 'd2': d2});
      setGraphXData(Object.keys(j).map(x => `${x.substring(5, 7)}`));
      graphDataSetter(Object.values(j));

      setIsLoading(false);
    };

    if (!yearFormat.test(graph1Year) || !yearFormat.test(graph2Year)) {
      return;
    }

    setIsLoading(true);
    update(graph1Year, setGraph1YData);
    update(graph2Year, setGraph2YData);
  }, [graph1Year, graph2Year]);

  return (
    <>
      <Box
        sx={{ width: '100%' }}
        mt={5}
        mb={2}
      >
        <Box sx={{ display: 'flex' }} gap={2}>
          <Box>
            <Typography fontSize='h6.fontSize' fontWeight='fontWeightBold'>
                年
              </Typography>
              <Typography fontSize={14}>
                入力例: 2023年 → 2023
            </Typography>
          </Box>
          <Box>
            <TextField
                label={graphNames[0]}
                value={graph1Year}
                onChange={(e) => {
                  setGraph1Year(e.target.value);
                }}
            />
          </Box>
          <Box>
            <TextField
              label={graphNames[1]}
              value={graph2Year}
              onChange={(e) => {
                setGraph2Year(e.target.value);
              }}
            />
          </Box>
        </Box>
      </Box>
      <Box sx={{ width: '100%' }}>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <LineChart
            xAxis={[{ data: graphXData, scaleType: 'band' }]}
            yAxis={[{ scaleType: 'linear', min: 0 }]}
            series={[
              { data: graph1YData, label: graphNames[0] },
              { data: graph2YData, label: graphNames[1] }
            ]}
            height={400}
          />
        )}
      </Box>
    </>
  );
};

export default Chart5;
