import ThreatCardDemo from '../comps/demo/ThreatCardDemo';
import ThreatDetailModalDemo from '../comps/demo/ThreatDetailModalDemo';
import ThreatSearchBarDemo from '../comps/demo/ThreatSearchBarDemo';
import ThreatDealerDemo from '../comps/demo/ThreatDealerDemo';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Unstable_Grid2';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CircleIcon from '@mui/icons-material/Circle';

const GuideCore = () => {
  return (
    <Box>
      <Box
        sx={{ width: '100%' }}
        mt={2}
        mb={2}
      >
        <Typography component='div'>
          <Box mt={5} mb={5} fontSize='h3.fontSize' fontWeight='fontWeightBold' textAlign='Center'>
            サイトの使い方
          </Box>
        </Typography>
        <Typography variant='body1' textAlign='Center' mt={5}>
          <Box fontSize='h6.fontSize' mt={1} mb={5}>
            私たちが見える化しているアクセスの見方を紹介します。
          </Box>
        </Typography>
      </Box>
      <Divider />
      <Box
        sx={{ width: '100%' }}
        mt={2}
        mb={2}
      >
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} mt={5} mb={7}>
            <Grid xs={6} pr={1}>
              <Box maxWidth={'400px'} mr='auto' ml='auto'>
                <ThreatCardDemo />
              </Box>
            </Grid>
            <Grid xs={6} pl={1}>
              <Typography fontSize='h5.fontSize' fontWeight='fontWeightBold'>
                意図しないアクセスを確認する
              </Typography>
              <Divider />
              <Box pl={2}>
                <Typography fontSize={18} mt={1} mb={1}>
                  Web サーバのログを、iLogScanner で調べた結果をまとめています。<br />
                </Typography>
                <List>
                  <ListItem key='demo-description-1-1'>
                    <ListItemIcon>
                      <CircleIcon fontSize='small' />
                    </ListItemIcon>
                    <ListItemText primary='アクセスがあった日時' />
                  </ListItem>
                  <ListItem key='demo-description-1-2'>
                    <ListItemIcon>
                      <CircleIcon fontSize='small' />
                    </ListItemIcon>
                    <ListItemText primary='アクセス元の IP アドレス' />
                  </ListItem>
                  <ListItem key='demo-description-1-3'>
                    <ListItemIcon>
                      <CircleIcon fontSize='small' />
                    </ListItemIcon>
                    <ListItemText primary='アクセスの種類' />
                  </ListItem>
                </List>
              </Box>
            </Grid>
            <Grid xs={12} mt={3} mb={3} pl={'5%'} pr={'5%'}>
              <Divider />
            </Grid>
            <Grid xs={6} pr={1}>
              <Box maxWidth={'400px'} mr='auto' ml='auto'>
                <ThreatDetailModalDemo />
              </Box>
            </Grid>
            <Grid xs={6} pl={1}>
              <Typography fontSize='h5.fontSize' fontWeight='fontWeightBold'>
                詳細な情報を確認する
              </Typography>
              <Divider />
              <Box pl={2}>
                <Typography fontSize={18} mt={1} mb={1}>
                  #demo1 をクリックすると詳細な情報を確認できます。
                </Typography>
                <List>
                  <ListItem key='demo-description-2-1'>
                    <ListItemIcon>
                      <CircleIcon fontSize='small' />
                    </ListItemIcon>
                    <ListItemText primary='アクセスがあった日時' />
                  </ListItem>
                  <ListItem key='demo-description-2-2'>
                    <ListItemIcon>
                      <CircleIcon fontSize='small' />
                    </ListItemIcon>
                    <ListItemText primary='アクセス元の IP アドレス' secondary='アクセス元の IP アドレスについて確認できます。' />
                  </ListItem>
                  <ListItem key='demo-description-2-3'>
                    <ListItemIcon>
                      <CircleIcon fontSize='small' />
                    </ListItemIcon>
                    <ListItemText primary='アクセスの種類' secondary='アクセスの種類についての解説を読むことができます。' />
                  </ListItem>
                  <ListItem key='demo-description-2-4'>
                    <ListItemIcon>
                      <CircleIcon fontSize='small' />
                    </ListItemIcon>
                    <ListItemText primary='アクセスの内容' secondary='実際のアクセスの内容を見ることができます。' />
                  </ListItem>
                </List>
              </Box>
            </Grid>
            <Grid xs={12} mt={3} mb={3} pl={'5%'} pr={'5%'}>
              <Divider />
            </Grid>
            <Grid xs={6} pr={1}>
              <Box maxWidth='400px' mr='auto' ml='auto'>
                <ThreatSearchBarDemo />
              </Box>
            </Grid>
            <Grid xs={6} pl={1}>
              <Typography fontSize='h5.fontSize' fontWeight='fontWeightBold'>
                絞り込む
              </Typography>
              <Divider />
              <Box pl={2}>
                <Typography fontSize={18} mt={1} mb={1}>
                  検索バーをクリックして、表示する情報を条件から絞り込むことができます。
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Divider />
      <Box
        sx={{ width: '100%' }}
        mt={2}
        mb={2}
      >
        <Typography component='div'>
          <Box mt={5} mb={5} fontSize='h4.fontSize' fontWeight='fontWeightBold' textAlign='Center'>
            デモ
          </Box>
        </Typography>
        <Typography variant='body1' textAlign='Center' mt={5}>
          <Box fontSize='h6.fontSize' mt={1} mb={5}>
            クリックして詳細を確認できたり、検索で絞り込めたりするので、試してみてください!<br />
          </Box>
        </Typography>
        <Box mb={5}>
          <ThreatDealerDemo />
        </Box>
      </Box>
    </Box>
  );
};

export default GuideCore;
