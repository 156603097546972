import Header from '../../common/Header';
import BodyBox from '../../common/BodyBox';
import HeadTitleBox from '../../common/HeadTitleBox';
import Speech from '../../common/Speech';
import ListTitleBox from '../../common/ListTitleBox';
import ThreatCasino from '../comps/casino/ThreatCasino';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const Threat = () => {
  return (
    <>
      <Header>
        <Typography
          style={{ fontSize: "0.8rem", fontWeight: "bold" }}
        >
          ISL(Information Security Lab.)
        </Typography>
        <Typography
          style={{ fontSize: "1.2rem", fontWeight: "bold" }}
        >
          東京電機大学 情報セキュリティ研究室
        </Typography>
      </Header>
      <BodyBox>
        <Box
          mb={5}
        >
          <HeadTitleBox>
            Webアクセスの見える化によるサイバー攻撃抑止の実証実験
          </HeadTitleBox>
          <Box>
            <Speech mb={4}>
              情報セキュリティ研究室は「Webアクセスの見える化によるサイバー攻撃抑止」の実証実験を推進しています。
              研究室への攻撃的なWebアクセス(SQLインジェクションなど)を特定・見える化し「①サイバー攻撃の抑止」「②第三者に悪用されている機器の特定・救済」に活用していきます。
            </Speech>
            <Box
              mb={3}
            >
              <ListTitleBox>
                研究室のWebサイト(= このWebサイト)のログから、研究室への攻撃的アクセスを特定
              </ListTitleBox>
              <Speech>
                IPAの「iLogScanner」を用いて「SQLインジェクション」や「OSコマンドインジェクション」などの攻撃的アクセスを特定します。
              </Speech>
            </Box>
            <Box>
              <ListTitleBox>
                研究室のWebサイト(= このWebサイト)で見える化
              </ListTitleBox>
              <Speech>
                研究室への攻撃的アクセスを見える化し、サイバー攻撃の抑止や、第三者に悪用されている機器の特定・救済を推進していきます。
              </Speech>
            </Box>
          </Box>
        </Box>
        <Box>
          <HeadTitleBox>
            攻撃的アクセスの一覧
          </HeadTitleBox>
          <ThreatCasino params={{ limit: '10' }} />
        </Box>
      </BodyBox>
    </>
  );
};

export default Threat;
