import { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';

const IPNameSearchBar = ({ updateIPNames }) => {
  const [doDisplayN, setDoDisplayN] = useState(true);
  const [displayN, setDisplayN] = useState('');
  const [doExactMatch, setDoExactMatch] = useState(true);
  const [searchBy, setSearchBy] = useState('');
  useEffect(() => {
    const params = {};
    if (doDisplayN) {
      if (displayN !== '') {
        params['limit'] = displayN;
      }
    }

    if (searchBy !== '') {
      params['domain'] = searchBy;
      params['doExactMatch'] = doExactMatch;
    }

    if (Object.keys(params).length === 0) {
      return;
    }

    updateIPNames(params);
  }, [doDisplayN, displayN, doExactMatch, searchBy])

  const labelDoAll = '件数を指定する';
  const labelDisplayN = '表示件数';
  const labelDoExactMatch = '完全一致で検索する';
  const labelSearchBy = 'アクセス元の名前';
  return (
    <Grid container spacing={2}>
      <Grid item xs={2}>
        <Box sx={{ mt: 'auto', mb: 'auto', height: '100%' }} display="flex" justifyContent="flex-end">
          <FormControlLabel control={<Switch defaultChecked />} label={labelDoAll} onChange={(e) => setDoDisplayN(e.target.checked)} />
        </Box>
      </Grid>
      <Grid item xs={4}>
        <TextField
          label={labelDisplayN}
          value={displayN}
          fullWidth
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          disabled={!doDisplayN}
          onChange={(e) => setDisplayN(e.target.value)}
        />
      </Grid>
      <Grid item xs={2}>
        <Box sx={{ mt: 'auto', mb: 'auto', height: '100%' }} display="flex" justifyContent="flex-end">
          <FormControlLabel control={<Switch defaultChecked />} label={labelDoExactMatch} onChange={(e) => setDoExactMatch(e.target.checked)} />
        </Box>
      </Grid>
      <Grid item xs={4}>
        <TextField
          label={labelSearchBy}
          value={searchBy}
          fullWidth
          onChange={(e) => setSearchBy(e.target.value)}
        />
      </Grid>
    </Grid>
  );
};

export default IPNameSearchBar;
