import Header from '../../common/Header';
import BodyBox from '../../common/BodyBox';
import HeadTitleBox from '../../common/HeadTitleBox';
import Speech from '../../common/Speech';
import ListTitleBox from '../../common/ListTitleBox';
import ThreatCasino from '../comps/casino/ThreatCasino';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LaunchIcon from '@mui/icons-material/Launch';

const ShareCore = () => {
  return (
    <>
      <Header>
        <Typography style={{ fontSize: "1.3rem", fontWeight: "bold" }}>
          攻撃的アクセスの一覧
        </Typography>
      </Header>
      <BodyBox>
        <Box mb={5}>
          <HeadTitleBox>
            攻撃的アクセスの一覧
          </HeadTitleBox>
          <Box>
            <Speech mb={3}>
              研究室への攻撃的アクセスの一覧です。アクセス元の詳細を調べたり、表示する攻撃的アクセスを絞り込んだりできます。
            </Speech>
            <Box mb={3}>
              <ListTitleBox>
                アクセス元のIPアドレスの詳細(所在地、組織など)を調べる
              </ListTitleBox>
              <Speech>
                <LaunchIcon sx={{ color: "sky.main" }} style={{ fontSize: "1rem" }} />からShodanのサイトに移動して、アクセス元のIPアドレスの所在地、組織などを調べることができます。
              </Speech>
            </Box>
            <Box mb={3}>
              <ListTitleBox>
                表示する攻撃的アクセスを絞り込む
              </ListTitleBox>
              <Speech>
                Searchボタンから条件を指定して、期間やIPアドレス、攻撃的アクセスの種類などで絞り込めます。
              </Speech>
            </Box>
          </Box>
        </Box>
        <ThreatCasino />
      </BodyBox>
    </>
  )
};

export default ShareCore;
