import IPNameSearchBar from './IPNameSearchBar';
import IPNameDeck from './IPNameDeck';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

const IPNameDealer = ( {ipnames, updateDeck, isWaitingDeck} ) => {
  return (
    <Box>
      <Box
        mt={1}
        mb={1}
      >
        <IPNameSearchBar updateIPNames={updateDeck} />
      </Box>
      <Box
        sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-evenly', gap: 2, width: '100%' }}
        mt={1}
        mb={1}
      >
        {isWaitingDeck ? (
          <CircularProgress />
        ) : (
          <IPNameDeck ipnames={ipnames} />
        )}
      </Box>
    </Box>
  );
};

export default IPNameDealer;
