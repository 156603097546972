import SpringModal from '../../../util/modal/SpringModal';
import AttackMethodTipsModal from './AttackMethodTipsModal';

import { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AttributionIcon from '@mui/icons-material/Attribution';
import HeartBrokenIcon from '@mui/icons-material/HeartBroken';
import CodeIcon from '@mui/icons-material/Code';
import { Link } from 'react-router-dom';

const ThreatDetailModal = ({ open, handleClose, index, threat, isDemo=false, needModalContentOnly=false }) => {
  const [anotherOpen, setOpen] = useState(false);
  const handleAnotherOpen = () => setOpen(true);
  const handleAnotherClose = () => setOpen(false);

  const ModalContent = () => {
    return (
      <Box>
        <Typography component='div'>
          <Box fontSize='h5.FontSize' fontWeight='fontWeightBold' textAlign='Center'>
            {isDemo ? (
              `#${index}`
            ) : (
              `#${index + 1}`
            )}
          </Box>
          <Divider />
          <List>
            <ListItem key={`${index}-access_clock`}>
              <ListItemButton>
                <ListItemIcon>
                  <AccessTimeIcon />
                </ListItemIcon>
                <ListItemText primary={threat.access_clock} />
              </ListItemButton>
            </ListItem>
            <ListItem key={`${index}-client_ip`}>
              {isDemo ? (
                <ListItemButton component={Link} to={`https://www.virustotal.com/gui/`} target='_blank'>
                  <ListItemIcon>
                    <AttributionIcon />
                  </ListItemIcon>
                  <ListItemText primary={threat.client_ip} secondary='IP アドレスの情報を確認できます!' />
                </ListItemButton>
              ) : (
                <ListItemButton component={Link} to={`https://shodan.io/host/${threat.client_ip}`} target='_blank'>
                  <ListItemIcon>
                    <AttributionIcon />
                  </ListItemIcon>
                  <ListItemText primary={threat.client_ip} secondary={`${threat.client_ip} について確認してみましょう!`} />
                </ListItemButton>
              )}
            </ListItem>
            <ListItem key={`${index}-attack_method`}>
              {isDemo ? (
                <ListItemButton onClick={handleAnotherOpen}>
                  <ListItemIcon>
                    <HeartBrokenIcon />
                  </ListItemIcon>
                  <ListItemText primary={threat.attack_method} secondary='アクセスの種類について解説します!' />
                </ListItemButton>
              ) : (
                <ListItemButton onClick={handleAnotherOpen}>
                  <ListItemIcon>
                    <HeartBrokenIcon />
                  </ListItemIcon>
                  <ListItemText primary={threat.attack_method} secondary={`${threat.attack_method} について解説します!`} />
                </ListItemButton>
              )}
            </ListItem>
            <ListItem key={`${index}-attack_code`}>
              {isDemo ? (
                <ListItemButton>
                  <ListItemIcon>
                    <CodeIcon />
                  </ListItemIcon>
                  <ListItemText primary={threat.request} secondary='実際に送られてきたリクエストを紹介します!' />
              </ListItemButton>
              ) : (
                <ListItemButton>
                  <ListItemIcon>
                    <CodeIcon />
                  </ListItemIcon>
                  <ListItemText primary={threat.request} secondary='実際に、このようなリクエストが送られてきました...' />
                </ListItemButton>
              )}
            </ListItem>
          </List>
        </Typography>
        <AttackMethodTipsModal open={anotherOpen} handleClose={handleAnotherClose} attackMethod={threat.attack_method} />
      </Box>
    );  
  }

  if (needModalContentOnly) {
    return <ModalContent />;
  }

  return (
    <SpringModal open={open} handleClose={handleClose} ModalContent={ModalContent} style={{ maxWidth: '90vw' }} />
  );
};

export default ThreatDetailModal;
