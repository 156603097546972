import ThreatDetailModal from '../modal/ThreatDetailModal';

import { useState } from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LaunchIcon from '@mui/icons-material/Launch';
import HeartBrokenIcon from '@mui/icons-material/HeartBroken';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

const ThreatCard = ({ index, threat, isDemo=false }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [accessY, accessHMS] = threat.access_clock.split('; ');
  const [accessHM, accessS] = [accessHMS.substr(0, 5), accessHMS.substr(5, 3)];

  return (
    <Box
      sx={{ position: "relative" }}
    >
      <Card
        sx={{ width: "260px", height: "350px" }}
      >
        <Box
          sx={{ display: "flex", alignItems: "center", width: "260px", height: "50px", margin: "auto", background: "#0D0D59" }}
        >
          <Box
            ml={1}
            mr={1}
          >
            <LocationOnIcon style={{ fill: "#fafafa" }} />
          </Box>
          <Typography
            style={{ fontSize: "1.78rem", color: "#fafafa" }}
          >
            {threat.client_ip}
          </Typography>

        </Box>
        <CardContent>
          <Box
            mb={3}
            style={{ cursor: "default" }}
          >
            <Typography
              style={{ fontFamily: "Time New Roman", fontSize: "3.0rem", textAlign: "center", lineHeight: "1.1", color: "#0D0D59" }}
            >
              {accessHM}<span style={{ fontSize: "1rem" }}>{accessS}</span>
            </Typography>
            <Typography
              style={{ fontFamily: "Time New Roman", fontWeight: "bold", fontSize: "0.8rem", textAlign: "center", color: "#0D0D59" }}
            >
              {accessY}
            </Typography>
          </Box>
          <Box>
            <Box
              sx={{ width: "100%" }}
            >
              <Button
                sx={{ padding: 0, width: "100%" }}
                component={Link}
                to={`https://shodan.io/host/${threat.client_ip}`}
                target='_blank'
              >
                <Box
                  mb={1}
                  p={1}
                  sx={{ width: "100%", borderRadius: 2 }}
                  style={{ backgroundColor: "rgba(108, 117, 125, 0.2)" }}
                >
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: 2 }}
                  >
                    <Box
                      sx={{ width: "160px" }}
                    >
                      <Box
                        mb={0.3}
                        sx={{ display: "flex", alignItems: "center", width: "100%" }}
                      >
                        <Box
                          mr={1}
                        >
                          <LocationOnIcon style={{ fontSize: "1.2rem", color: "#0D0D59" }} />
                        </Box>
                        <Typography
                          style={{ fontSize: "1.1rem", fontWeight: "bold",  color: "#0D0D59" }}
                        >
                          {threat.client_ip}
                        </Typography>
                      </Box>
                      <Box>
                      <Typography
                        style={{ fontSize: "0.7rem" }}
                      >
                        攻撃的なアクセス元の場所や<br />マシンの状態を確認できます。
                      </Typography>

                      </Box>
                    </Box>
                    <Box>
                      <LaunchIcon />
                    </Box>
                  </Box>
                </Box>
              </Button>
            </Box>
            <Box
              mb={1}
              p={1}
              sx={{ width: "100%", borderRadius: 2 }}
              style={{ backgroundColor: "rgba(108, 117, 125, 0.2)" }}
            >
              <Box
                sx={{ display: "flex", alignItems: "center", width: "100%", height: "100%" }}
              >
                <Box
                  mr={1}
                >
                  <HeartBrokenIcon style={{ fontSize: "1.2rem", color: "#0D0D59" }} />
                </Box>
                <Typography
                  style={{ fontSize: "0.9rem", fontWeight: "bold", color: "#0D0D59" }}
                >
                  {threat.attack_method}
                </Typography>
              </Box>
            </Box>
          </Box>
        </CardContent>
        <Box
            sx={{ position: "absolute", bottom: 0, width: "100%", height: "1rem", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#0D0D59" }}
        >
          <MoreHorizIcon style={{ position: "relative", fill: "#fafafa", fontSize: "1rem" }} />
        </Box>
      </Card>
      <ThreatDetailModal open={open} handleClose={handleClose} index={index} threat={threat} isDemo={isDemo} />
    </Box>
  );
};

export default ThreatCard;
