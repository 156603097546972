import Typography from "@mui/material/Typography";

const Speech = (props) => {
  const { sx, style, mr, mb, ml, mt } = props;
  return (
    <Typography
      mr={mr}
      mb={mb}
      ml={ml}
      mt={mt}
      sx={sx}
      style={{ lineHeight: 1.8, ...style }}
    >
      { props.children }
    </Typography>
  );
};

export default Speech;
