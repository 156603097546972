// import Origin from '../origin/Origin';
import OriginWrapper from './origin/Origin';
import TestCore from './core/TestCore';

const Test = () => {
  return (
    // <Origin Core={TestCore} />
    <OriginWrapper Core={TestCore} />
  );
}

export default Test;
