import Origin from '../../origin/Origin';

import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

const OriginWrapper = ({ Core }) => {
  const verify = async() => {
    // const URL = '/api/verify/continue'
    const URL = 'https://172.18.0.46/api/verify/continue'
    const resJson = await fetch(URL, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify()
    })
    .then(async (res) => {
      return await res.json();
    })
    .catch((err) => {
      console.error(err);
    })

    return resJson;
  };

  const [isVerified, setIsVerified] = useState(false);
  const [redirectTo, setRedirectTo] = useState('');
  useEffect(() => {
    const checkAuthentication = async () => {
      const verified = await verify();
      if (verified.pass) {
        setIsVerified(true);
      } else {
        setRedirectTo(verified.retry);
      }
    }

    checkAuthentication();
  }, []);

  return (
    <>
      {isVerified ? (
        <Origin Core={Core} />
      ) : (
        <Navigate replace to={redirectTo} />
      )}
    </>
  );
};

export default OriginWrapper;
